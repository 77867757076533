import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Immunity from "../../../static/images/icons/immunity.svg"

export default function Advantages({ title, content, pluses }) {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "michalina03.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="advantages" className="gray-bg">
      <div className="container-full">
        <div className="row no-gutters">
          <div className="col-md-6 ">
            <BackgroundImage
              Tag="div"
              className="h-100"
              fluid={query.bannerImg.childImageSharp.fluid}
              style={{
                backgroundPosition: "top center",
              }}
            ></BackgroundImage>
          </div>
          <div className="col-md-6">
            <div className="paragraph-space paragraph-content">
              <h2 className="section-subtitle">{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <h3>Nasze wartości to:</h3>
              <div className="row mt-4">
                {pluses.map(plus => {
                  return (
                    <div className="col-md-6 icon-paragraph">
                      <div className="border-icon">
                        <Immunity />
                      </div>
                      <h5 className="icon-paragraph-title">{plus.name}</h5>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
