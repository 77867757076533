import React from "react"

export default function FacebookPosts({title, content}) {
  return (
    <section id="facebook-posts" className="section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="text-box-padding-xs-right">
              <h2 className="section-subtitle">{title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
          <div className="col-lg-6 facebook-widget">
            <iframe
              loading="lazy"
              title="facebook-page-widget"
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbiuro.mlabecka&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId&lazy=true"
              width="500"
              height="500"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}
