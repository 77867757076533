import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Banner({title, content}) {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "michalina01.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const scrollView = (event) => {
    event.preventDefault();
    document
      .getElementById("services")
      .scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return (
    <section className="banner-section gray-bg">
      <div className="container-full">
        <div className="row no-gutters align-items-center">
          <div className="col-sm-8">
            <Img fluid={query.bannerImg.childImageSharp.fluid} alt="Banner" />
          </div>
          <div className="col-sm-4">
            <div className="banner-text-container paragraph-space">
              <h1>{title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <a className="button" href="#about-us" onClick={scrollView}>
                Zobacz więcej
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
