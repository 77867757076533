import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/global/Seo"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer"

import Banner from "../components/home/Banner"
import Services from "../components/home/Services"
import Advantages from "../components/home/Advantages"
import FacebookPosts from "../components/home/FacebookPosts"
import ContactSection from "../components/home/ContactSection"

export default function Home({ data }) {
  const { site, banner, whyUs, social } = data
  return (
    <div>
      <SEO title="Home" titleTemplate={site.siteMetadata.title} />
      <Header />
      <Banner title={banner.frontmatter.title} content={banner.html} />
      <Services />
      <Advantages
        title={whyUs.frontmatter.title}
        content={whyUs.html}
        pluses={whyUs.frontmatter.pluses}
      />
      <FacebookPosts title={social.frontmatter.title} content={social.html} />
      <ContactSection isHome={true} />
      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    banner: markdownRemark(fileAbsolutePath: { regex: "/(home/banner)/" }) {
      html
      frontmatter {
        title
      }
    }
    whyUs: markdownRemark(fileAbsolutePath: { regex: "/(home/whyUs)/" }) {
      html
      frontmatter {
        title
        pluses {
          name
        }
      }
    }
    social: markdownRemark(fileAbsolutePath: { regex: "/(home/social)/" }) {
      html
      frontmatter {
        title
      }
    }
  }
`
